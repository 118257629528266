import React from "react";
import { StaticImage } from "gatsby-plugin-image";

// import {
//   PhoneIncomingIcon,
//   ReceiptTaxIcon,
//   RefreshIcon,
// } from "@heroicons/react/solid";

//art-moval-fond-service.jpg
export default function SectionService() {
  return (
    <div className="relative isolate overflow-hidden py-24 sm:py-32">
      <div className="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center opacity-85">
        <StaticImage
          className="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center brightness-75"
          src="../../images/art-moval-fond-service.jpg"
          alt="art Moval Service"
        />
      </div>


      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl lg:mx-0 text-art-beige">
          <h2 className="text-4xl font-bold tracking-tight  sm:text-6xl">
            Nos solutions de déménagement pour particuliers, entreprises et
            collectivités
          </h2>
          <p className="mt-6 text-lg leading-8 ">
            Nous nous occupons du déménagement de logements, bureaux, usines, en
            France ou à l'international. Nous proposons également un service de
            garde meubles.
          </p>
          <p className="mt-6 text-lg leading-8 ">
            Conscients que chaque déménagement est unique, nous vous proposons
            des prestations adaptées à vos besoins.
          </p>
          <p className="mt-6 text-lg leading-8 ">
            Parce que déménager est souvent synonyme de stress, le choix de
            votre déménageur est très important. Pour cela, faites nous
            confiance. Nous vous accompagnons durant toutes les étapes : de la
            prise de rendez-vous pour la visite commerciale jusqu’à votre
            emménagement (et même après si besoin !).
          </p>
        </div>

        <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          <article className="flex flex-col items-start justify-between">

            <div className="relative w-full">
              <a href="/demenagement-particuliers/">
                <StaticImage
                  className="aspect-[16/9] w-full rounded-t-[30px] bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                  src="../../images/art-moval-service-particulier.jpg"
                  alt="Art Moval service particulier"
                />
                <div className="absolute inset-0 rounded-t-[30px] ring-1 ring-inset ring-gray-900/10" />
              </a>
            </div>
            <div className="w-full gap-x-4 rounded-b-[30px] p-6 ring-1 ring-inset ring-gray-900/10 bg-art-beige mb-8">
              <a href="/demenagement-particuliers/">
                <div className="group relative">

                  <h3 className="mt-3 text-xl font-semibold leading-6 text-art-marron-fonce group-hover:text-gray-600">
                    <span className="absolute inset-0" />
                    <StaticImage
                      className="h-5 w-5 flex-none mr-4"
                      src="../../images/Maison_45_Bleu.png"
                      alt="Art Moval Maison"
                    />
                    Déménagement de particuliers
                  </h3>
                  <p className="mt-6 text-lg leading-8 text-art-marron-fonce">
                    Nous sommes déménageurs près de Lyon depuis plus de 25 ans. En
                    travaillant avec nous, vous profitez d’un réel savoir-faire,
                    d’un service serein, et d’une équipe de déménageurs
                    professionnels et expérimentés
                  </p>

                </div>
              </a>
            </div>

          </article>
          <article className="flex flex-col items-start justify-between">

            <div className="relative w-full">
              <a href="/demenagement-entreprises/">
                <StaticImage
                  className="aspect-[16/9] w-full rounded-t-[30px] bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                  src="../../images/art-moval-service-professionnel.jpg"
                  alt="Art Moval service professionnel"
                />
                <div className="absolute inset-0 rounded-t-[30px] ring-1 ring-inset ring-gray-900/10" />
              </a>
            </div>
            <div className="h-full w-full gap-x-4 rounded-b-[30px] p-6 ring-1 ring-inset ring-gray-900/10 bg-art-beige mb-8">
              <a href="/demenagement-entreprises/">
                <div className="group relative">
                  <h3 className="mt-3 text-xl font-semibold leading-6 text-art-marron-fonce group-hover:text-gray-600">
                    <span className="absolute inset-0" />
                    <StaticImage
                      className="h-5 w-5 flex-none mr-4"
                      src="../../images/Maison_45_Bleu.png"
                      alt="Art Moval Maison"
                    />
                    Déménagement d’entreprises
                  </h3>
                  <p className="mt-6 text-lg leading-8 text-art-marron-fonce">
                    Transfert industriel, déménagement ou transfert de bureaux, de
                    siège sociaux, ou encore de cabinets d’avocats, de médecins ou
                    d’usines… Nous vous apportons des solutions sur-mesure,
                    parfaitement adaptées à vos attentes
                  </p>
                </div>
              </a>
            </div>
          </article>
          <article className="flex flex-col items-start justify-between">

            <div className="relative w-full">
              <a href="/garde-meubles/">
                <StaticImage
                  className="aspect-[16/9] w-full rounded-t-[30px] bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                  src="../../images/art-moval-service-garde-meuble.jpg"
                  alt="Art Moval service garde meuble"
                />
                <div className="absolute inset-0 rounded-t-[30px] ring-1 ring-inset ring-gray-900/10" />
              </a>
            </div>
            <div className="h-full w-full gap-x-4 rounded-b-[30px] p-6 ring-1 ring-inset ring-gray-900/10 bg-art-beige mb-8">
              <a href="/garde-meubles/">
                <div className="group relative">
                  <h3 className="mt-3 text-xl font-semibold leading-6 text-art-marron-fonce group-hover:text-gray-600">
                    <span className="absolute inset-0" />
                    <StaticImage
                      className="h-5 w-5 flex-none mr-4"
                      src="../../images/Maison_45_Bleu.png"
                      alt="Art Moval Maison"
                    />
                    Garde-meubles
                  </h3>
                  <p className="mt-6 text-lg leading-8 text-art-marron-fonce">
                    Nous stockons vos affaires dans notre entrepôt sécurisé pour
                    une durée déterminée ou indéterminée
                  </p>
                </div>
              </a>
            </div>

          </article>
          <article className="flex flex-col items-start justify-between">
            <div className="relative w-full">
              <StaticImage
                className="aspect-[16/9] w-full rounded-t-[30px] bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                src="../../images/art-moval-service-destruction-archive.jpg"
                alt="Art Moval service destruction archive"
              />
              <div className="absolute inset-0 rounded-t-[30px] ring-1 ring-inset ring-gray-900/10" />
            </div>
            <div className="h-full w-full  gap-x-4 rounded-b-[30px] p-6 ring-1 ring-inset ring-gray-900/10 bg-art-beige mb-8">
              <div className="group relative">
                <h3 className="mt-3 text-xl font-semibold leading-6 text-art-marron-fonce group-hover:text-gray-600">
                  <span className="absolute inset-0" />
                  <StaticImage
                    className="h-5 w-5 flex-none mr-4"
                    src="../../images/Maison_45_Bleu.png"
                    alt="Art Moval Maison"
                  />
                  Destruction d’archives
                </h3>
                <p className="mt-6 text-lg leading-8 text-art-marron-fonce">
                  Selon vos instructions, nous détruisons de façon définitive
                  vos documents confidentiels, et nous vous fournissons ensuite
                  un certificat de destruction
                </p>
              </div>
            </div>
          </article>
          <article className="flex flex-col items-start justify-between">
            <div className="relative w-full">
              <StaticImage
                className="aspect-[16/9] w-full rounded-t-[30px] bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                src="../../images/art-moval-service-decheterie.jpg"
                alt="Art Moval service decheterie"
              />
              <div className="absolute inset-0 rounded-t-[30px] ring-1 ring-inset ring-gray-900/10" />
            </div>
            <div className="h-full w-full  gap-x-4 rounded-b-[30px] p-6 ring-1 ring-inset ring-gray-900/10 bg-art-beige mb-8">
              <div className="group relative">
                <h3 className="mt-3 text-xl font-semibold leading-6 text-art-marron-fonce group-hover:text-gray-600">
                  <span className="absolute inset-0" />
                  <StaticImage
                    className="h-5 w-5 flex-none mr-4"
                    src="../../images/Maison_45_Bleu.png"
                    alt="Art Moval Maison"
                  />
                  Mise en déchetterie
                </h3>
                <p className="mt-6 text-lg leading-8 text-art-marron-fonce">
                  Pour encore plus de sérénité dans votre déménagement, nos
                  équipes se chargent de la mise en déchetterie. Nous séparons
                  les biens réutilisables et recyclables
                </p>
              </div>
            </div>
          </article>
          <article className="flex flex-col items-start justify-between">
            <div className="relative w-full">
              <StaticImage
                className="aspect-[16/9] w-full rounded-t-[30px] bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                src="../../images/art-moval-service-manutention.jpg"
                alt="Art Moval service manutention"
              />
              <div className="absolute inset-0 rounded-t-[30px] ring-1 ring-inset ring-gray-900/10" />
            </div>
            <div className="h-full w-full  gap-x-4 rounded-b-[30px] p-6 ring-1 ring-inset ring-gray-900/10 bg-art-beige mb-8">
              <div className="group relative">
                <h3 className="mt-3 text-xl font-semibold leading-6 text-art-marron-fonce group-hover:text-gray-600">
                  <span className="absolute inset-0" />
                  <StaticImage
                    className="h-5 w-5 flex-none mr-4"
                    src="../../images/Maison_45_Bleu.png"
                    alt="Art Moval Maison"
                  />
                  Manutentions diverses
                </h3>
                <p className="mt-6 text-lg leading-8 text-art-marron-fonce">
                  Faites appel à nos déménageurs professionnels pour
                  manutentionner vos objets lourds, encombrants ou fragiles
                </p>
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
  );
}
