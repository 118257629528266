import React from "react";
import { StaticImage } from "gatsby-plugin-image";

export default function SectionEntreprise() {
  return (
    <div className="relative ">
      <div className="relative h-80 overflow-hidden  md:absolute md:left-0 md:h-full md:w-1/3 lg:w-1/2">

        <StaticImage
          className="h-full w-full object-cover"
          src="../../images/demenagement-entreprise.jpg"
          alt="Art Moval Entreprise"
        />

      </div>
      <div className="relative mx-auto max-w-7xl py-24 sm:py-32 lg:px-8 lg:py-16">
        <div className="pl-8 pr-6 md:ml-auto md:w-2/3  lg:w-1/2  lg:pr-0 ">
          <h2 className="mt-10 text-4xl font-bold tracking-tight text-art-marron-fonce sm:text-6xl">
            Transfert administratif, archives, stock, informatique…
          </h2>
          <p className="mt-6 text-lg leading-8 text-art-marron-fonce">
            Nous sommes les spécialistes du déménagement d'entreprises à Lyon.
          </p>
          <p className="mt-6 text-lg leading-8 text-art-marron-fonce">
            Le déménagement et le transfert administratif d'une entreprise à Lyon nécessitent une planification rigoureuse. Chez Art Moval, nous veillons à respecter votre budget tout en optimisant la gestion du temps et des ressources humaines.
          </p>
          <p className="mt-6 text-lg leading-8 text-art-marron-fonce">
            Nous prenons en compte tous les aspects du déménagement de votre entreprise : de l'agencement du mobilier à la connexion des équipements, en passant par toutes les nécessités pour une reprise rapide de vos activités dans vos nouveaux locaux.
          </p>

          <div className="mt-10 flex items-center gap-x-6">
            <a
              href="/contact/"
              className="rounded-md bg-art-orange px-3.5 py-2.5 text-lg font-semibold text-white shadow-sm hover:bg-art-bleu focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Déposez votre demande
            </a>
            <a
              href="/demenagement-entreprises/"
              className="text-lg font-semibold leading-6 text-art-marron-fonce"
            >
              Votre déménagement d’entreprise <span aria-hidden="true">→</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
