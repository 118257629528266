import React from "react";
import { StaticImage } from "gatsby-plugin-image";


export default function SectionHistoire() {
  return (
    <div className="relative isolate overflow-hidden bg-art-beige">
      <svg
        className="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
        aria-hidden="true"
      >
        <defs>
          <pattern
            id="0787a7c5-978c-4f66-83c7-11c213f99cb7"
            width={200}
            height={200}
            x="50%"
            y={-1}
            patternUnits="userSpaceOnUse"
          >
            <path d="M.5 200V.5H200" fill="none" />
          </pattern>
        </defs>
        <rect
          width="100%"
          height="100%"
          strokeWidth={0}
          fill="url(#0787a7c5-978c-4f66-83c7-11c213f99cb7)"
        />
      </svg>
      <div className="mx-auto max-w-7xl px-6 pt-10 pb-24 sm:pb-32 lg:flex lg:py-40 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl lg:flex-shrink-0">
          {/* Maison_37_Bleu.png */}
          <div className="w-40">
            <StaticImage
              className=""
              src="../../images/Maison_37_Bleu.png"
              alt="Art Moval Logo"
            />
          </div>
          {/* <div className="mt-24 sm:mt-32 lg:mt-16">
            <a href="#" className="inline-flex space-x-6">
              <span className="rounded-full bg-indigo-600/10 px-3 py-1 text-sm font-semibold leading-6 text-indigo-600 ring-1 ring-inset ring-indigo-600/10">
                What's new
              </span>
              <span className="inline-flex items-center space-x-2 text-sm font-medium leading-6 text-gray-600">
                <span>Just shipped v1.0</span>
                <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </a>
          </div> */}
          <h2 className="mt-10 text-4xl font-bold tracking-tight text-art-marron-fonce sm:text-6xl">
            Art Moval à Lyon
          </h2>
          <p className="mt-6 text-lg leading-8 text-art-marron-fonce">
            Créée en 1996, notre entreprise familiale est spécialisée dans le
            déménagement de particuliers et le déménagement d'entreprises
            (transferts industriels, déménagements de bureaux ou locaux
            professionnels : usines, cabinets, écoles…) en France et à
            l'étranger.
          </p>
          <p className="mt-6 text-lg leading-8 text-art-marron-fonce">
            <strong>Art Moval</strong>, située à Lyon (69), est membre du réseau
            « Les Gentlemen du Déménagement ».
          </p>
          <p className="mt-6 text-lg leading-8 text-art-marron-fonce">
            En vous déménageant, nous transportons une partie de votre vie, ce
            qui nécessite un soin particulier. De la visite technique à la
            réalisation de la prestation, nous restons à votre écoute. Notre
            savoir-faire est pour vous un gage de qualité et de sérénité.
          </p>
          <p className="mt-6 text-lg leading-8 text-art-marron-fonce">
            Pour toutes informations complémentaires, nous restons à votre
            entière disposition : contactez nous ! »
          </p>
          <div className="mt-10 flex items-center gap-x-6">
            <a
              href="/contact/"
              className="rounded-md bg-art-orange px-3.5 py-2.5 text-lg font-semibold text-white shadow-sm hover:bg-art-bleu focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Déposez votre demande
            </a>
            <a
              href="/nous-connaitre/"
              className="text-lg font-semibold leading-6 text-art-marron-fonce"
            >
              Nous connaître <span aria-hidden="true">→</span>
            </a>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              Notre agence
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              76 Avenue Charles de Gaulle 69160 TASSIN
            </dd>
          </div>
        </div>
        <div className="hidden lg:block mx-auto mt-16 max-w-2xl sm:mt-24 lg:ml-10 lg:mt-0 lg:mr-0 lg:max-w-none lg:flex-none xl:ml-32">
          <div className="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none">
            <div className="-m-2 rounded-xl bg-gray-900/5 p-2 ring-1 ring-inset ring-gray-900/10 lg:-m-4 lg:rounded-2xl lg:p-4">
              <div className="w-[76rem] rounded-md shadow-2xl ring-1 ring-gray-900/10">
                <StaticImage
                  className="w-[76rem] rounded-md shadow-2xl ring-1 ring-gray-900/10"
                  src="../../images/art-moval-histoire.jpg"
                  alt="Art Moval Logo"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
