import React from "react"

const incentives = [
  {
    name: 'Savoir-faire depuis plus de 25 ans',
    imageSrc: 'https://tailwindui.com/img/ecommerce/icons/icon-shipping-simple.svg',
    description: "",
  },
  {
    name: 'Visite commerciale et devis gratuits',
    imageSrc: 'https://tailwindui.com/img/ecommerce/icons/icon-warranty-simple.svg',
    description: "",
  },
  {
    name: 'Formule sur-mesure, adaptée à vos besoins',
    imageSrc: 'https://tailwindui.com/img/ecommerce/icons/icon-exchange-simple.svg',
    description:
      "",
  },
]

export default function SectionEngagement() {
  return (
    <div className="bg-art-beige">
      <div className="mx-auto max-w-7xl pt-24 sm:px-2 sm:pt-32 lg:px-4">
        <div className="mx-auto max-w-2xl px-4 lg:max-w-none">
          <div className="max-w-3xl">
            <h2 className="text-4xl font-bold tracking-tight text-art-marron-fonce sm:text-6xl">
              Nos engagements
            </h2>
          </div>
          <div className="mt-16 grid grid-cols-1 gap-y-10 gap-x-8 lg:grid-cols-3">
            {incentives.map((incentive) => (
              <div key={incentive.name} className="sm:flex lg:block">
                <div className="sm:flex-shrink-0">
                  <img className="h-16 w-16" src={incentive.imageSrc} alt="" />
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-6 lg:mt-6 lg:ml-0">
                  <h3 className="text-lg font-bold text-art-marron-fonce">{incentive.name}</h3>
                  <p className="mt-2 text-sm text-art-marron-fonce">{incentive.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
